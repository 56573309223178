import React from "react";
import Seo from "../components/seo";
import logo from "../images/evistamplogoNew.png";
const Cookies = () => {
  return (
    <>
      <Seo title="Cookies policy" description="Cookies Policy for Evistamp" />
      <div>
        <div className="policy-heading">
          <img className="policy-logo" src={logo} />
          <h3>EVISTAMP Website Cookie Policy </h3>
          <p>
            Version 1.0 <br />
            Effective Date: April 1st, 2022 <br />
            Last Update: April 1st, 2022
            <br />
            <a href="https://evistamp.com">wwww.evistamp.com</a>{" "}
          </p>
        </div>
        <div className="policy-text">
          <ul>
            <li>What is the purpose of this document?</li>
            <p>
              This Cookie Policy is designed to provide you with clear and
              accessible information about the cookies used by Ricta
              Technologies S.R.L. on the Evistamp platform at{" "}
              <a href="https://www.evistamp.com">https://www.evistamp.com</a>{" "}
              and the choices you have to control them.
            </p>
            <p>
              Ricta Technologies S.R.L. (hereinafter “we”, “us” or similar) is a
              Romanian limited company, whose registered office is in Lujerului
              Street, no 10, District 6, Bucharest, Romania, registered with the
              Bucharest Trade Registry under number J40/2684/2020, tax code
              42312660.
            </p>
            <p>
              When you are using the Evistamp service (hereinafter the
              “Product”) or access{" "}
              <a href="https://www.evistamp.com"> https://www.evistamp.com</a>{" "}
              website (hereinafter the “Website”) and together are constituting
              Evistamp Platform, we collect and process several categories of
              personal data from you as representative of our client (“Client”
              or “you”).
            </p>
            <p>
              For further information on how we use, store, and otherwise
              process personal data, please refer to our Website Privacy Policy
              and Client Privacy Policy. If you have any questions about this
              Cookie Policy, please contact us at:{" "}
              <a href="mailto:privacy@evistamp.com">privacy@evistamp.com</a>
            </p>
            <p>
              By continuing to browse our website{" "}
              <a href="https://evistamp.com">https://evistamp.com </a>and any
              other subdomain *.evistamp.com (hereinafter the “Website”), you
              are agreeing to our use of cookies for the purposes specified
              herein.
            </p>
            <li>What are cookies and why do we use them?</li>
            <p>
              A cookie is a small text file that can be automatically placed on
              your computer, mobile or any other device when you access or
              otherwise use the Website or Services and further recalled by a
              web server that placed the cookie.
            </p>
            <p>
              Some cookies can be created by our authentication service, solely
              to manage access to the personal area.
            </p>
            <li>What type of cookies there are and which ones we use?</li>
            <p>
              <p>We use different types of cookies:</p>
              <ul>
                <li>
                  <b>Strictly necessary cookies.</b> These are cookies that are
                  required for the operation of the Website. They include, for
                  example, cookies that enable you to log into secure areas of
                  the Website. The essential cookies do not collect your data
                  that could be further used for marketing purposes. Should you
                  opt to block the essential cookies in your web browser, then
                  you may be prevented from accessing or using the Website and
                  Services provided by us.
                </li>
                <li>
                  <b>Analytical/performance cookies.</b> We do not have
                  analytical cookies
                </li>
              </ul>
            </p>
            <li>How can you manage cookies on your device?</li>
            <p>
              You can adjust the privacy settings in your browser to block all
              cookies; however, this could severely affect your browsing
              experience as many websites may not function properly. Your
              browser may allow you to delete all cookies upon closing your
              browser. This option, though, results in persistent cookies
              getting deleted that may store your preferences and personalized
              settings on websites that you visit regularly. It is possible to
              keep desired cookies, though, as your browser may allow you to
              specify which websites are always or never allowed to use cookies.
            </p>
            <p>
              <p>
                Most browsers allow you to view, manage, delete and block
                cookies. Guidance on how to control cookies for common browsers
                is linked below:
              </p>
              <ul>
                  <li><a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en">Google Chrome</a></li>
  
                  <li><a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy">Microsoft Edge </a></li>
                  <li> <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop">Mozilla Firefox</a></li>
                  <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft Internet Explorer</a></li>
                  <li><a href="https://www.opera.com/help/tutorials/security/privacy/">Opera</a></li>
                  <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Apple Safari</a></li>
              </ul>
              <p>To find information related to other browsers, please visit the browser developer's website.</p>
              <p>
Comprehensive information on how organizations use cookies is available at: <a href="www.allaboutcookies.org">www.allaboutcookies.org</a></p>
            </p>
            <li>Changes to this Cookie Policy</li>
            <p>We may update this Cookie Policy at any time and, therefore, we encourage you to review this Cookie Policy regularly to stay informed about our use of cookies. The date at the top of this Cookie Policy indicates when it was last updated.</p>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Cookies;
